import React from 'react';
import { graphql } from 'gatsby';

import HeroTypeB from '../components/Heroes/HeroTypeB';
import StoreLocationTiles from '../components/Location/StoreLocationTiles';
import HowToOrderOnline from '../components/Banners/HowToOrderOnline';
import BannerInfoTypeA from '../components/Banners/BannerInfoTypeA';
import BannerTypeA from '../components/Banners/BannerTypeA';
import CategorySection from '../components/Category Section/CategorySection';
import FaqSection from '../components/FAQ/FaqSection';
import SuperHeader from '../components/Location/SuperHeader';
import LocationSearchSection from '../components/Location/LocationSearchSection';
import SEO from '../components/SEO/SEO';

export default function StoresPage({ data }) {
  const hero_type_b = data.strapiStoresPage.Hero_Type_B;
  const location_tile_section = data.strapiStoresPage.Location_Tile_Section;
  const info_banner_type_a = data.strapiStoresPage.Info_Banner_Type_A;
  const category = data.strapiStoresPage.Categroy_Section;
  const banner_type_middle = data.strapiStoresPage.Blog_Banner_Type_A;
  const banner_type_bottom = data.strapiStoresPage.Blog_Banner_A;
  const faq = data.strapiStoresPage.FAQ;
  const location_search =
    data.strapiLocationsSearchComponent.Location_With_Search;

  return (
    <>
      <SEO
        title="Ontario’s #1 Cannabis Stores | House of Cannabis"
        description="Toronto dispensary | Keswick dispensary | Barrie dispensary | 
                    Kingston dispensary | Four convenient dispensary locations across Ontario"
      />
      <HeroTypeB
        title={hero_type_b.Title}
        heroImage={
          hero_type_b.BackgroundImage.localFile.childImageSharp.gatsbyImageData
        }
        buttonLabel={hero_type_b.Button.Label}
        buttonLink={hero_type_b.Button.Custom_Link_Slug}
      />
      <StoreLocationTiles tiles={location_tile_section.Location_Tile} />
      <HowToOrderOnline loc_slug="/menu" />
      <CategorySection categorydata={category.Block} title={category.Title} />
      <BannerInfoTypeA
        title={info_banner_type_a.Title}
        description={info_banner_type_a.Content}
        bgImage={
          info_banner_type_a.Image.localFile.childImageSharp.gatsbyImageData
        }
      />
      <SuperHeader
        title="ORDER ONLINE FROM THE COMFORT OF YOUR HOME"
        link="/menu"
      />
      <BannerTypeA
        title={banner_type_middle.Article_Link.Title}
        description={banner_type_middle.Article_Link.Preview_Text}
        bannerImage={
          banner_type_middle.Article_Link.BannerImage.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/menu"
        buttonLabel="READ ARTICLE"
      />

      {/*  {`/blog/${banner_type_middle.Article_Link.slug}`} */}

      <LocationSearchSection
        titles={location_search.Title}
        locations={location_search.Location_Single}
      />
      <FaqSection faqdata={faq.FAQs} title={faq.Title} />
      <BannerTypeA
        title={banner_type_bottom.Title}
        description={banner_type_bottom.Content}
        bannerImage={
          banner_type_bottom.Background_Image.localFile.childImageSharp
            .gatsbyImageData
        }
        link={banner_type_bottom.Blog_Button_Link.Link_Slug}
        buttonLabel={banner_type_bottom.Blog_Button_Link.Button_Label}
      />
    </>
  );
}

export const query = graphql`
  query {
    strapiStoresPage {
      Hero_Type_B {
        Title
        Button {
          Custom_Link_Slug
          Label
        }
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }

      Location_Tile_Section {
        Location_Tile {
          Location_Title
          Location_Subtitle
          Location_Address
          Link_Slug
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
      }

      Categroy_Section {
        Block {
          Title
          Link_Slug
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 70)
              }
            }
          }
        }
        Title
      }

      FAQ {
        FAQs {
          Answer
          Question
        }
        Title
      }

      Info_Banner_Type_A {
        Title
        Content
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }

      Blog_Banner_A {
        Title
        Content
        Blog_Button_Link {
          Link_Slug
          Button_Label
        }

        Background_Image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
      }

      Blog_Banner_Type_A {
        Article_Link {
          Preview_Text
          Title
          slug
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
      }
    }

    strapiLocationsSearchComponent {
      Location_With_Search {
        Title
        Location_Single {
          Link_Slug
          Location_Address
          Location_Subtitle
          Location_Title
          Monday_To_Saturday_Hours
          Sunday_Hours
        }
      }
    }
  }
`;
