import React from 'react';
import styled from 'styled-components';
import LocationTile from './LocationTile';

const MOBILE_BREAKPOINT = '992px';

const Container = styled.div`
  margin: 100px 0 145px;
  padding: 0 calc(5vw);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    margin: 50px 0 60px;
  }
`;

const Title = styled.h2`
  padding: 0 30%;
  margin-bottom: 45px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 130%;
  text-align: center;
  text-transform: uppercase;
  color: #2d2b2c;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0 60px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
    font-size: 24px;
  }
`;

const TilesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: minmax(0, 1fr);
  gap: 45px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }

  @media screen and (max-width: 414px) {
    grid-template-columns: minmax(0, 1fr);
  }
`;

export default function StoreLocationTiles({ tiles }) {
  return (
    <Container>
      <Title>Six Wonderful Weed Dispensaries Across Ontario</Title>
      <TilesGrid>
        {tiles.map((tile) => (
          <LocationTile
            bgImage={tile.Image.localFile.childImageSharp.gatsbyImageData}
            locationTitle={tile.Location_Title}
            locationSubtitle={tile.Location_Subtitle}
            locationAddress={tile.Location_Address}
            link={tile.Link_Slug}
            hasGradient
          />
        ))}
      </TilesGrid>
    </Container>
  );
}
