import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const MOBILE_BREAKPOINT = '768px';

const BGWrapper = styled.div`
  .bg-image {
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.75) 50%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }

  .bg-image::after {
    background-position-y: 50%;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .bg-image,
    .bg-image::before,
    .bg-image::after {
      background-position: 50% center;
    }
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  margin: 0 auto;
  padding: 80px 0;
  padding-right: 0px;
  text-align: center;
  font-style: normal;
  color: #ffffff;

  .hoc-button-outline {
    display: inline-block;
    margin-bottom: 7vw;
    font-size: 16px;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    width: 100%;
    padding: 70px 20px;
  }
`;

const Title = styled.h1`
  margin-bottom: 40px;
  font-family: 'Oswald';
  font-weight: 500;
  font-size: 64px;
  line-height: 130%;
  text-transform: uppercase;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    margin-bottom: 30px;
    font-size: 36px;
  }
`;

export default function HeroTypeB({
  title,
  heroImage,
  buttonLabel,
  buttonLink,
}) {
  const image = getImage(heroImage);
  const bgImage = convertToBgImage(image);

  return (
    <BGWrapper>
      <BackgroundImage
        Tag="div"
        {...bgImage}
        preserveStackingContext
        className="bg-image"
        style={{
          width: '100%',
          minHeight: '80vh',
          backgroundPosition: '',
          position: 'relative',
        }}
      >
        <Container>
          <Title>{title}</Title>
          <Link to={buttonLink}>
            <button className="hoc-button-outline">{buttonLabel}</button>
          </Link>
        </Container>
      </BackgroundImage>
    </BGWrapper>
  );
}
