import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

export default function SuperHeader({ title }) {
  const Header = styled(Link)`
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    color: var(--darkgreen);
    text-decoration: underline;
    &:hover {
      color: var(--lightgreen);
    }
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 30px 20px;
  `;
  return <Header>{title}</Header>;
}
